import React, { useEffect } from "react";
import Calander from "../components/Calande";

function Calanderpage() {
  return (
    <div className="Calanderpage">
      <Calander />
    </div>
  );
}

export default Calanderpage;
